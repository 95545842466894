<template>
  <div class="page">
    <div class="video-box">
      <div class="video" >
        <div class="video-iframe" :class="{'active': isPlay}" >
          <iframe frameborder="0" @click="isPlay = true" :src="'https://v.qq.com/txp/iframe/player.html?vid=' + vid" allowFullScreen="true"></iframe>
        </div>
        <!-- <div class="poster-box" v-if="!isPlay" >
          <img class="poster fit-img" :src="info.photo" >
          <div class="play-icon" bindtap="onPlay">
            <img src="~@/assets/images/icon/icon_play.svg" class="icon-play">
            <span>点击播放</span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="header-info">
      <div class="title">{{ info.title }}</div>
      <div class="info">
        <div class="item">
          <img class="icon" src="~@/assets/images/icon/icon_time.svg">
          <span>{{ info.sub_date }}</span>
        </div>
        <div class="item">
          <img class="icon" src="~@/assets/images/icon/icon_watch.svg">
          <span>{{ info.browse_count }}人已浏览</span>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="content-detail" v-html="content"></div>
    </div>
    <div class="footer-line">
      <span>我也是有底线的～</span>
    </div>
  </div>
</template>

<script>
  import { getVideoDetail } from '@/api/maintenance'
  import { Toast } from 'vant'

  export default {
    data() {
      return {
        id: '',
        vid: '',
        info: {},
        content: '',
        isPlay: false
      }
    },
    created() {
      this.id = this.$route.query.id
      this.loadData()
    },
    methods: {
      loadData() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getVideoDetail({id: this.id}).then(res => {
        const info = res.data.info
        this.info = info
        this.vid = info.code
        this.content = info.content || ''
        toast.clear()
      })
      }
    },
  }
</script>
<style lang="less">
  .content-detail {
    p, span {
      font-size: 3.4667vw !important;
      color: #FFFFFF !important;
      letter-spacing: 0.1333vw;
      text-align: justify;
      line-height: 2;
    }
    img {
      width: 100%;
      border-radius: 1.0667vw;
    }
  }
</style>
<style lang="less" scoped>
  .page {
    padding-top: 5.3333vw;
    padding-bottom: calc(5.3333vw + env(safe-area-inset-bottom));
    box-sizing: border-box;
    min-height: 100vh;
    background: #1a1a1a;
  }
  .video-box {
    width: 92vw;
    height: 51.7333vw;
    margin: 0 auto ;
    border-radius: 1.0667vw;
    overflow: hidden;
    position: relative;
    .video-iframe {
      position: absolute;
      width: 92vw;
      height: 51.7333vw;
      border-radius: 1.0667vw;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 10;
      iframe {
        width: 92vw;
        height: 51.7333vw;
        opacity: 1;
      }
      &.active {
        opacity: 1;
        iframe {
          opacity: 1;
        }
      }
    }
  }
  .poster-box {
    width: 92vw;
    height: 51.7333vw;
    border-radius: 1.0667vw;
    overflow: hidden;
    position: relative;
    background-color: #f6f7f8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .poster-box .poster {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  .poster-box::after {
    content: '';
    display: block;
    background: rgba(0,0,0,.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .poster-box .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.6667vw;
  }
  .poster-box .play-icon img {
    width: 5.8667vw;
    height: 5.8667vw;
  }
  .poster-box .play-icon span {
    font-weight: bold;
    font-size: 3.4667vw;
    color: #FFFFFF;
    text-align: center;
    line-height: 3.4667vw;
    margin-top: 3.2vw;
  }
  .header-info {
    margin: 5.3333vw 4vw 0;
  }
  .header-info .title {
    font-weight: bold;
    font-size: 4.2667vw;
    color: #FFFFFF;
    text-align: justify;
    line-height: 1.5;
  }
  .info {
    display: flex;
    align-items: center;
    margin-top: 2.6667vw;
  }
  .info .item {
    display: flex;
    position: relative;
    align-items: center;
  }
  .info .item +.item {
    margin-left: 8vw;
  }
  .info .item + .item::before {
    content: '';
    display: block;
    width: 0.2667vw;
    height: 2.1333vw;
    background: #9C9DAA;
    position: absolute;
    left: -4vw;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%)
  }
  .info .item img {
    width: 4.2667vw;
    height: 4.2667vw;
    vertical-align: top;
  }
  .info .item span {
    margin-left: 2vw;
    font-size: 3.2vw;
    color: #9C9DAA;
    line-height: 1;
  }
  .content-box {
    margin: 5.3333vw 4vw 0;
    padding-top: 4.5333vw; 
    border-top: 0.1333vw solid rgba(229,229,229,.3);
    .content-detail {
      p, span {
        font-size: 3.4667vw !important;
        color: #FFFFFF !important;
        letter-spacing: 0.1333vw;
        text-align: justify;
        line-height: 2;
      }
    }
  }

  .footer-line {
    padding-top: 5.4667vw;
    border-top: 0.1333vw solid rgba(229,229,229,.3);
    margin: 5.3333vw 4vw 0;
    text-align: center;
  }
  .footer-line span {
    width: 100%;
    font-size: 3.2vw;
    color: #999999;
    line-height: 1;
  }

</style>